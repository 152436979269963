import React from 'react'

export const About = () => {
    return (

        <div>

            <section className="page_breadcrumbs ds color parallax section_padding_top_75 section_padding_bottom_75">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <h2 className="text-uppercase">About Us</h2>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ls section_padding_top_100 section_padding_bottom_70">
                <div className="container">
                    <div className="text-center">
                        <h2 className="section_header to_animate">OMEGA <br /> <span className="highlight">DIES AND tools</span> </h2></div>
                    <div className="row">
                        <div className="col-sm-6">
                            <section className="text-center ">
                                <img src="about1.jpeg" alt="map" />
                            </section>
                        </div>
                        <div className="col-sm-6 text-center ">
                            <div className="aboutus">
                                <p className="fontsize_20 to_animate">
                                Omega Dies and Tools the proud owner of world renowned brand Anand Dies and Tools is manufacturing and exporting Wire Drawing Dies since last four & half decades. 
                                    The company was established in year 1980 and has positioned itself as the industry
                                    leader.
                                    <br />
                                    <br />
                                    The Company takes pride in the attention to detail it provides to each die during the
                                    inspection process. All the geometric facets such as shape, landing angle, bearing length,
                                    exit angle are checked during the inspection process. It helps in achieving greater throughput
                                    during the production. Production halths are reduced and consistent wire quality is achieved.
                                    <br />
                                    <br />
                                    We are one of the leading manufactures of Wire Drawing TC Dies in India. To attain superior
                                    quality, we use only contect certified TC Pellets. from M/s Kennametal India Limited in wire
                                    drawing TC Pellets segment. Our monthly production is approx 10,000 dies.
                                    <br />
                                    <br />
                                    Wire Drawing Dies for MIG Wire has been the flagship
                                    product of Standard Dies And Tools for more than a decade. Our years of experience
                                    go into every die we make and ensure that our customer can draw with confidence.
                                    We welcome the opportunity to share our expertise with our customers to help them
                                    solve the most demanding die engineering problems.
                                    <br />
                                    <br />
                                    The company was established by Mr. Ashok Anand under whose guidance
                                    it has blossomed into a world class manufacturing unit.
                                    The family business is thriving well and is now managed by the
                                    second generation.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </section>


        </div>

    )
}
export default About;