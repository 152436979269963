import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';

export const Header = () => {

    const [expanded, setExpanded] = useState(false);

    return (
        <div>

            <section className="page_topline ls section_padding_15">
                <div className="container-fluid ">
                    <div className="row">
                        <div className="col-md-4 col-sm-12 col-md-push-4 text-center">
                            <a className="social-icon color-icon soc-facebook" href="https://www.facebook.com/Anand-Dies-and-Tools-112097984592116" target="_blank" title="Facebook"></a>
                            <a className="social-icon color-icon soc-linkedin" href="https://www.linkedin.com/company/anand-dies-and-tools/" target="_blank" title="LinkedIn"></a>
                            <button className="btn-brochure" >
                                <a className="brochure" href="adt.pdf" target="_blank" title="Download Brochure" download="omegadiesandtools"><i className="fa fa-download black rightpadding_5"></i></a>
                            </button>
                            
                        </div>
                        <div className="col-md-4 col-sm-6 col-md-pull-4 text-center text-md-left">
                            <div className="inline-content small-text">
                                <p> <i className="fa fa-phone grey rightpadding_5"></i> <a href="tel:+91-9971602777" title="Call us">9971602777 </a>,  <a href="tel:+91-9810181182" title="Call us">9810181182</a></p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 text-center text-md-right ">
                        <a className="btn btn-primary" style={{color:'#fff',fontWeight:'bold',fontSize:'19px'}} href="https://diescalculator.omegadiesandtools.com" target="_blank" title="Dies Calculator">Dies Series Calculator</a>
                          
                            {/* <p >
                                <i className="fa fa-envelope grey rightpadding_5"></i> 
                                <a href="mailto:info@ananddiesandtools.com" title="Write us">info@ananddiesandtools.com</a>
                            </p> */}
                        </div>

                    </div>
                </div>
            </section>

            <hr/>

            <Navbar collapseOnSelect expand="lg" expanded={expanded} >
                <Container>
                    <Navbar.Brand href="/">
                        <img
                            src="logo.png"
                            width="200"
                            height="50"
                            className="d-inline-block align-top"
                            alt="logo"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav"
                        onClick={() => setExpanded(expanded ? false : "expanded")}
                    />
                    <Navbar.Collapse id="responsive-navbar-nav ">
                        <Nav className="ms-auto ">
                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <Link className="nav-link mobile-active" to="/" onClick={() => setExpanded(false)}>Home</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link mobile-active" to="/about" onClick={() => setExpanded(false)}>About Us</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link mobile-active" to="/products" onClick={() => setExpanded(false)}>Products</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link mobile-active" to="/contactus" onClick={() => setExpanded(false)}>Contact Us</Link>
                                </li>
                               
                            </ul>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

           
        </div>
    )
}
export default Header;